.footer {
    $widthContainer: 1800px;
    background-color: $bgcFooter;
    position: relative;
    padding-top: rem(25px);
    padding-bottom: 0;
    line-height: 1.67em;
    font-size: rem(16px);
    z-index: 15;

    &, a {
        color: $colorFooter;
    }

    a[onmouseout], a[onmouseover] {
        font-weight: 700;
    }

    &-container {
        @include container($widthContainer !important);
    }

    &-col {
        display: flex;
        flex-direction: column;

        @media (min-width: 768px) {
            @include colAuto;

            &:not(:first-child) {
                border-left: 1px solid #fff;
            }
        }

        @media (max-width: 767px) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    @media (max-width: 767px) {
        &-col1, &-col2 {
            @include colSize(50%);
        }

        &-col3 {
            @include colSize(100%);
        }
    }

    &-title {
        text-transform: uppercase;
        font-weight: 700;
        display: block;
        font-size: rem(13px);
    }

    &-list {
        margin: 0;
        list-style-type: none;
        font-size: rem(14px);
    }

    .bouton {
        color: $primary;

        &:hover, &:focus, &:active {
            color: #fff;
        }
    }

    &-imgContainer {
        display: flex;
        justify-content: center;
        width: 100%;

        @media (max-width: 767px) {
            padding-bottom: 15px;
        }
    }

    &-logo {
        height: auto;
        width: rem(245px);
        padding-bottom: 15px;
    }

    &-copyright {
        padding-top: rem(50px);
        padding-bottom: rem(50px);
        text-align: center;

        &, a {
            font-size: rem(16px);
        }
    }

    &-link {
        transition: transform .2s;

        &:hover {
            transform: translateY(-.4rem);
        }
    }
}

#list-footer {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: $colorFooter;

    a {
        color: inherit;
    }
}
