.breadcrumb {
    padding-top: 0;
    background-color: transparent;
    font-size: rem($fzText * .8);
    padding-left: 0;
    margin: 0;
    color: #fff;

    br {
        display: none;
    }

    & > &-item + &-item::before {
        color: #fff;
    }

    & &-link {
        color: rgba(white, .5);
    }
}
