.rs {
    display: flex;
    flex-wrap: wrap;

    $parent: &;

    &-list {
        display: flex;
        align-items: center;
        list-style-type: none;
        margin: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    &-item {
        display: flex;
        margin-right: rem(10px);

        &:last-child #{$parent}-link {
            margin-right: 0;
        }
    }

    &-link {
        display: inline-flex;

        &:focus,
        &:hover {
            .rs-icon {
                opacity: .5;

                // @media (max-width: 1779px) {
                //   fill: white;
                // }
            }
        }
    }

    &-icon {
        width: rem(25px);
        height: rem(25px);
        fill: white;
        vertical-align: text-top;

        // @media (max-width: 1779px) {
        //   fill: $color1;
        // }
    }
}
