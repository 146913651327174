html {
    font-size: $html-font-size;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;

    @media (max-width: 991px) {
        font-size: rem($html-font-size * .8);
    }
}

body {
    margin: 0;
    padding: 0;
    text-align: left;
    background: $body-bg;
    font-family: $ffText;
    color: $colorText;
    font-size: rem($fzText);
    --swiper-theme-color: #{$swiperThemeColor};
    --swiper-pagination-bullet-height: #{rem(4px)};
    --swiper-pagination-bullet-width: #{rem(4px)};
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: #{rem(10px)};
}

#content {
    position: relative;
}

.hidden {
    display: none;
}

h1, h2, h3, h4, h5, h6 {
    text-transform: none;
    font-family: $ffTitle;
    color: $colorTitle;
    margin-bottom: 16px;
    font-weight: 700;
    margin-top: 0;
}

h1 {
    font-family: $ffTitle;
    text-align: center;
}

$listStyles: ("h1": $fzH1, "h2": $fzH2, "h3": $fzH3, "h4": $fzH4, "h5": $fzH5, "h6": $fzH6);

@each $element, $size in $listStyles {
    #{$element} {
        font-size: rem($size);
    }
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="week"],
input[type="month"],
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
optgroup,
select,
textarea,
.form-control {
  border: 1px solid gainsboro;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  background-color: #fff;
}

ul, ol {
    margin: 10px;
    padding: 0;
    list-style-position: inside;
}

a {
    transition: all 0.2s linear;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }

    img {
        border: 0;
    }

    // &[href$=".pdf"] {
    //     padding: 10px 0 10px 30px;
    //     background: url(/img/pdf.png) no-repeat 0 50% transparent;
    // }
}

blockquote {
    margin: 30px 0;
    padding: 5px 15px;
    border-left: 4px solid #eee;
    @include ffItalic;
}

em {
    @include ffItalic;
}

strong {
    @include ffBold;
}

.overflow {
    overflow: hidden;
}

.scrollToTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000000;
    display: none;

    &-icon {
        fill: $primary;
        width: 35px;
        height: 35px;
    }
}
/* #CONTENT
================================================== */

// #content {
//     padding-bottom: 20px;
// }

/* ------ mentions légales -----
#mentions-bloc { font-size: 0.8em; color: #000; width: 440px; position: fixed; left: 50%; margin-left: -220px; top: 50px; background: #fff; border: 10px solid #eee; padding: 10px; border-radius: 6px; display: none; box-shadow: 0 0 2px #000; z-index: 100000; }
@media (max-width: 767px) {
#mentions-bloc { width: 400px; font-size: 0.7em; margin-left: -200px}
}
#close_mentions { width: 20px; height: 20px; cursor: pointer; position: absolute; top: -5px; right: -5px; background: url(../img/close_mentions.png) no-repeat transparent; text-indent: -9999px; }

#mentions-bloc {
max-width: 550px;
border-radius: 4px;
color: #000;
font-size: 0.8em;
transform: translateY(-50px);
transition: all .33s;
}
*/

.fancybox-slide--current #hidden-content-b {
    transform: translateY(0);
}

/* ----- NOTIFICATIONS -----*/

.result_error {
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: #e57c7c;
}

.result_success {
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: #78ca71;
}

#flashMessage {
    background: #fff;
    color: #337ab7;
    line-height: 40px;
    text-align: center;
}

// Connexion
#tableau_login { overflow:hidden; }

#compte { margin: 20px auto}

#UserSiteLoginForm div, #UserAddUserForm div { margin: 0 0 10px; }

.error-message { color: #a94442}

// Contact
.accept {
    display: inline;
}

.error {
    .form-control {
        border-color: $danger;
    }
    .control-label {
        color: $danger;
    }
}

.mandatory {
    height: 0.1px;
    width: 0;
    border: 0 !important;
    padding: 0 !important;
}

.mentionRGPD {
    font-size: rem(14px);
}

#map {
    background: $gray-200;
    width: 100%;
    height: 500px;
    color: $black;
}

/* ----- COOKIE BAR ----- */

#cookie-bar {
    background: #111111;
    height: auto;
    line-height: 24px;
    color: #eeeeee;
    text-align: center;
    padding: 3px 0;
    font-size: 0.8em;
    z-index: 999;

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        &.bottom {
            bottom: 0;
            top: auto;
        }
    }

    p {
        margin: 0;
        padding: 0;
    }

    a {
        color: #ffffff;
        display: inline-block;
        border-radius: 3px;
        text-decoration: none;
        padding: 0 6px;
        margin-left: 8px;
    }

    .cb-enable {
        background: #3ac976;

        &:hover {
            background: #009900;
        }
    }

    .cb-disable {
        background: #990000;

        &:hover {
            background: #bb0000;
        }
    }

    .cb-policy {
        background: #0033bb;

        &:hover {
            background: #0055dd;
        }
    }
}

/* ----- DEMO ASSETS ----- */

.bs-component {
    .modal {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        bottom: auto;
        z-index: 1;
        display: block;
    }

    .modal-dialog {
        width: 90%;
    }
}

.modal-title {
    margin-top: 0;
}

/* ----- Message de consentement RGPD ----- */

.mentionRGPD {
    font-size: 0.8em;
}

// pagination
.pagination {
    justify-content: center;
}

.page {
    &-item {

    }

    &-link {

    }
}

$btns:
"primary" $primary,
"secondary" $secondary,
"success" $green,
"danger" $red,
"warning" $yellow,
"info" $cyan,
"light" $light,
"dark" $dark;

@each $modifier, $colorBtn in $btns {
    .btn-#{$modifier} {
        @include bouton($hover: false, $color: $colorBtn);

        &:hover, &:focus, &:active {
            @include hoverBouton($color: $colorBtn);
        }
    }

    .btn-outline-#{$modifier} {
        @include boutonOutline($hover: false, $color: $colorBtn);

        &:hover, &:focus, &:active {
            @include hoverBoutonOutline($color: $colorBtn);
        }
    }
}

.social {
    &-tabs {
        display: flex;
        z-index: 4995;
        padding-left: 15px;
        padding-right: 15px;

        @media (min-width: 992px) {
            position: sticky;
            bottom: 0;
            justify-content: flex-end;
        }

        @media (max-width: 991px) {
            justify-content: center;
            background-color: $primary;
        }
    }

    &-frame {
        padding: rem(15px) rem(80px);
        display: inline-flex;
        align-items: center;

        @media (min-width: 992px) {
            @include background(url(../../../images/motif.png), contain, center bottom);
            position: absolute;
            bottom: 0;
            right: 0;
        }

        @media (max-width: 991px) {
            justify-content: center;
        }
    }
}
