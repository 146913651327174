// Body
$body-bg: transparent;

// Typography
$font-family-sans-serif: 'open_sansregular', Arial, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$light: #f8f9fa;
$dark: #343a40;

$primary: #c4d345; // vert
$secondary: #9d9d9d;
$darkColor: #686868;

$bgcHeader: none;
$bgcFooter: $primary;
$bgcMenu: transparent;
$colorFooter: white;

$fzH1: 37px;
$fzH2: 30px;
$fzH3: 14px;
$fzH4: 21px;
$fzH5: 28px;
$fzH6: 26px;

$colorTitle: black;
$colorText: black;
$colorLink: $primary;

$colorMenu: $darkColor;

$swiperThemeColor: black;

$fzText: 16px;

$ffSpecial: 'Caveat Brush', cursive;
$ffTitle: 'Open Sans', sans-serif;
$ffText: 'Open Sans', sans-serif;
$ffBold: false;
$ffItalic: false;

$widthContainer: 1840px;

$heightMenu: 140px;
$heightMenuMobile: 60px;

$mobile: '(max-width: 767px)';
$phone-portrait: '(max-width: 575px)';
$phone-landscape: '(min-width: 576px) and (max-width: 767px)';
$tablet-portrait: '(min-width: 768px) and (max-width: 991px)';
$tablet-landscape: '(min-width: 992px) and (max-width: 1199px)';
$large-desktop: '(min-width: 1200px) and (max-width: 1919px)';
$semi-desktop1: '(min-width: 1200px) and (max-width: 1439px)';
$semi-desktop2: '(min-width: 1440px) and (max-width: 1919px)';
$wide-desktop: '(min-width: 1920px)';
$non-retina: 'screen and (-webkit-max-device-pixel-ratio: 1)';
$retina: '(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)';
