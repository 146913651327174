body.page-9 #content {
    padding-bottom: 0;
}

.engagements {
    $mobileBP: 992;

    &-container {
        @include container(1800px);
    }

    &-strate, &-visuel {
        @media (min-width: 768px) {
            min-height: 100vh;
        }

        @media (max-width: 767px) {
            min-height: 100vh;
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    &-strate {
        display: flex;
        align-items: center;

        @media (min-width: 768px) {
            padding-top: $heightMenu;
            padding-bottom: $heightMenu;
        }

        @media (max-width: 767px) {
            padding-top: $heightMenuMobile;
            padding-bottom: $heightMenuMobile;
        }

        &, h1, h2, h3, h4 {
            color: #fff;
        }

        h2 {
            font-size: 20px;
            font-weight: 800;
        }

        h3 {
            font-size: 16px;
            margin-bottom: 0;
        }

        a {
            color: #fff;
        }
    }

    &-container, &-row {
        height: 100%;
    }

    &-row {
        align-items: center;

        @media (max-width: #{$mobileBP - 1}px) {
            flex-direction: column;
            justify-content: center;
        }
    }

    &-col {
        display: flex;
        flex-direction: column;

        @media (min-width: #{$mobileBP}px) {
            justify-content: center;
            height: 100%;
        }

        @media (max-width: #{$mobileBP - 1}px) {
            align-items: center;
        }
    }

    &-top {
        align-items: center;
    }

    $colors: #88a38b, #94b1bb, #77728c, #667e5f;

    $i: 0;
    @each $color in $colors {
        &-strate#{$i} {
            background-color: $color;
        }
        $i: $i + 1;
    }

    &-bg {
        height: auto;
        max-width: 100%;
    }

    &-visuel {
        position: relative;
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        max-width: 100vw;
        max-height: 100vh;
    }

    $images: 'nature.jpg', 'sea.jpg', 'forest.jpg' ;

    $i: 0;
    @each $image in $images {
        &-visuel#{$i} {
            background-image: url(../../../images/engagements/#{$image});
        }

        $i: $i + 1;
    }

    &-img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        max-height: 100%;
        max-width: 100%;
    }

    &-text {
        max-width: 550px;

        @media (max-width: #{$mobileBP - 1}px) {
            text-align: center;
        }
    }
}
