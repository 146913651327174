.header {
    background: $bgcHeader;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5000;
    padding-top: 0;
    padding-bottom: 0;
    position: fixed;
    pointer-events: none;

    &-container {
        display: flex;

        @media (max-width: 991px) {
            max-width: 100%;
        }
    }

    &-row {
        align-items: center;
        justify-content: center;
    }
}
