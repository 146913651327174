@use "sass:string";

//partials
@import './front/base/appfront';
@import './front/page/engagements';

// custom styles
body:not(.page-5) {
    #content {
        @media (min-width: 992px) {
            padding-top: rem($heightMenu);
        }
    }
}

body:not(.page-5) {
    h1 {
        font-family: $ffSpecial;
        font-size: rem(75px);
        line-height: 0.9;
        margin-bottom: 0.5em;
        font-weight: 400;
    }

    .container {
        @include container(1140px);
    }
}
