#menu {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;

    @media (min-width: 1200px) {
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 1199px) {
        width: 100%;
    }
}

.primaryMenu {
    background-color: $bgcMenu;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    pointer-events: auto;
    @include colAuto(0);

    @media (min-width: 1200px) {
        @include background(url(../../../images/bg-menu.png), $pos: center bottom);

        &:hover &-dropdown {
            opacity: 1;
            transition: opacity 0s;
            transition: opacity .3s ease-in .5s;

            &::before {
                height: 0;
                margin-top: 0;
            }
        }
    }

    @media (max-width: 1199px) {
        background-color: #fff;
        padding: 0;
    }


    @media (max-width: 1199px) {
        min-height: auto;
    }

    &-toggler {
        font-size: 25px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0;
        z-index: 15;
        padding-right: 0;
        padding-top: 15px;
    }

    &-icon {
        width: 30px;
        height: 30px;
        fill: #000;
        max-width: 100%;
        max-height: 100%;
    }

    &-collapse {
        max-height: 100vh;
        overflow-y: auto;
    }

    &-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: 0;
        padding-bottom: rem(55px);
        align-items: flex-start;

        @media (max-width: 1199px) {
            padding-top: 45px;
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            padding-bottom: 15px;
        }

        &::before, &::after {
            content: none;
        }
    }

    &-item {
        &--niv1 {
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &-link {
        // height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 13px;
        color: $colorMenu;
        text-transform: uppercase;
        padding: 0;

        &--niv1:hover {
            color: $colorMenu;
            text-decoration: underline;
        }
    }

    &-dropdown {
        display: block;
        position: static !important;
        border: none;
        border-radius: 0;
        background: none;

        @media (min-width: 1200px) {
            opacity: 0;
            transition: opacity .3s;

            &::before {
                content: '';
                height: 100%;
                display: block;
                margin-top: -100%;
                transition: margin-top .5s ease-in, height .5s ease-in;
            }
        }
    }

    &-dropdownItem {
        &:hover, &:focus {
            background: none;
        }
    }
}

.megamenu-content {
    &-child {
        &:first-child, [href$="#footer"] {
            font-weight: 800;
        }
    }
}

#free {
    &-1, &-2, &-3, &-4 {
        > a {
            font-size: 0;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            height: rem(50px);
            width: rem(150px);
        }
    }

    $imgs: 'KARMA-KOMBUCHA.png', 'YA.png', 'Ferments.png', 'biogroupe.svg';

    $i: 1;
    @each $img in $imgs {
        &-#{$i} > a {
            background-image: url(../../../images/#{$img});
        }
        $i: $i + 1;
    }
}
