.langues {
    $parent: &;
    padding: 0 rem(25px);

    &-link {
        font-size: rem(17px);
        font-weight: 200;
        text-transform: uppercase;
        color: #fff;
        transition: unset;

        &:not(#{$parent}--current):hover, &:not(#{$parent}--current):active {
            color: #fff;
            opacity: .5;
        }

        &:not(:last-child) {
            &::after {
                content: '_';
            }
        }

        &--current {
            font-weight: 600;
            pointer-events: none;
        }
    }
}
