@mixin container($size) {
    @media(min-width: 1200px) {
        max-width: $size;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}

@mixin background($src, $size: cover, $pos: center, $repeat: no-repeat) {
    background-image: $src;
    background-size: $size;
    background-position: $pos;
    background-repeat: $repeat;
    transition: background-image .2s;
}

@mixin highlight {
    background-color: map-get($menu, bgColorLinkHover);
    text-decoration: none;
    color: map-get($menu, colorLinkHover);

    // @media (max-width: 1779px) {
    //   background-color: $color1;
    //   color: white;
    // }
}

@mixin ffBold($ffBold: $ffBold) {
    @if $ffBold {
        font-family: $ffBold;
    }
    @else {
        font-weight: 700;
    }
}

@mixin ffItalic($ffItalic: $ffItalic) {
    @if $ffItalic {
        font-family: $ffItalic;
    }
    @else {
        font-style: italic;
    }
}

@mixin respond-to($media) {
    @media only screen and #{$media} {
        @content;
    }
}

@mixin colAuto($pd: 15px) {
    flex: 1 1 0;
    max-width: 100%;
    padding-left: $pd;
    padding-right: $pd;
}

@mixin colSize($size, $pd: 15px) {
    flex: 0 0 $size;
    max-width: $size;
    padding-left: $pd;
    padding-right: $pd;
}

@mixin bouton($hover: true, $color: $primary) {
    color: color-yiq($color);
    text-decoration: none;
    padding: .5em 1em;
    border-radius: 0;
    border-width: 0;
    display: inline-block;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s;
    outline: none;
    background-color: $color;
    font-size: rem($fzText);

    @if $hover {
        &:hover, &:focus, &:active {
            @include hoverBouton($color);
        }
    }
}

@mixin hoverBouton($color: $primary) {
    $color: rgba($color, .4);
    color: color-yiq($color);
    background: $color;
    text-decoration: none;
    cursor: pointer;
}

@mixin boutonOutline($hover: true, $color: $primary) {
    color: $color;
    text-decoration: none;
    padding: .5em 1em;
    border-radius: 0;
    border: 1px solid $color;
    display: inline-block;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s;
    outline: none;
    background-color: transparent;
    font-size: rem($fzText);
}

@mixin hoverBoutonOutline($color: $primary) {
    color: color-yiq($color);
    background: $color;
    text-decoration: none;
    cursor: pointer;
}

@mixin shadow {
    &::after {
        content: '';
        position: absolute;
        bottom: -.2rem;
        left: 50%;
        width: 120%;
        height: 5%;
        border-radius: 100%;
        background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 50%, rgba(255,255,255,0) 100%);
        transform: translateX(-50%);
        filter: blur(.6rem);
        // z-index: -1;
        transition: transform .3s ease-in-out;
    }
}
